/* Colors */
$primary-color: #23BCED

@keyframes animloader
  0% 
    left: 0
    transform: translateX(-100%)
  100% 
    left: 100%
    transform: translateX(0%)


//Fix select label width
.MuiInputLabel-root
    background-color: white
    padding-right: 3px
    
.MuiToolbar-root.MuiToolbar-regular.css-r6ewbb-MuiToolbar-root
    height: 120px

.MuiToolbar-root.MuiToolbar-regular
    min-width: 644px

a.go-home
    position: absolute
    width: 92px
    height: 82px
    z-index: 1
    top: 20px
    left: 30px

.logo-out-navbar
    display: block
    position: absolute
    padding: 14px 8px
    background-color: white
    border: 2px solid black
    height: 82px
    width: 92px

nav
    padding-left: clamp(120px, 0%, 0px)
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-flex: 1
    -webkit-flex-grow: 1
    -ms-flex-positive: 1
    flex-grow: 1
    min-width: 360px

nav a
    font-weight: 500
    font-size: 0.875rem
    letter-spacing: 0.1em
    text-decoration: none
    color: white
    text-transform: uppercase
    padding: 0px 30px
    line-height: 120px

nav a.active
    background-color: white
    color: #23bced

@media (max-width: 750px)
    nav a
        padding: 0 10px

.ol-viewport
    display: none

.ol-viewport:last-child
    display: block

/* Location Step */
.c40 .map-container-location
    position: relative

.c40 .select-location
    position: absolute
    z-index: 10
    width: 250px
    margin-top: 8px
    right: 10px

    .MuiSelect-select
        padding: 8px 34px 8px 10px
        box-shadow: 6px 6px 6px rgba(0,0,0,.16)
        transition: all .4s ease
        background-color: #FFFFFF

        &:hover
            box-shadow: 3px 3px 3px rgba(0,0,0,.16)

    .Mui-focused, label.MuiFormLabel-root
        //background-color: #fff        
        //border-radius: 4px

    .MuiSelect-icon
        color: $primary-color


/* Results Step */    

.hide
  display: none

.show
  display: block

c40 .results-map.got-city

    .ol-viewport
        width: calc(100% - 426px) !important

.c40 .results-map
    position: absolute
    left: 0
    width: 100%
    height: calc(100vh - 160px)
    margin-top: 40px

    .ol-viewport
        width: 100%
        transition: right .3s ease

    .toggleLoadProject.hide
        opacity: 0

    .toggleLoadProject.loader .lds-dual-ring
        display: inline-block
        position: absolute
        width: 80px
        height: 80px

        &:after
            content: " "
            display: block
            width: 64px
            height: 64px
            margin: 8px
            border-radius: 50%
            border: 6px solid #fff
            border-color: #fff transparent #fff transparent
            animation: lds-dual-ring 1.2s linear infinite

    .toggleLoadProject
        position: absolute
        left: 10px
        z-index: 2
        background-color: white
        top: 6px        
        transition: all 1s ease
        opacity: 1

        button
            background-color: #23BCED
            color: white
            line-height: 1em
            transition: all .4s ease
            box-shadow: 6px 6px 6px rgba(0,0,0,.16)

        button.Mui-selected
            background-color: #008CB9
            pointer-events: none
            box-shadow: 3px 3px 3px rgba(0,0,0,.16)

        button.Mui-selected:hover
            background-color: #008CB9
            color: white

        button:hover
            color: gray
            background-color: #89daf5
            box-shadow: 3px 3px 3px rgba(0,0,0,.16)

    #panel 
        top:0
  
    #selected-city
        top: calc(100% - 73px)

    #selected-city.got-city
        top: calc(100% - 73px)

    #selected-city.got-city.got-score
        top: calc(100% - 210px)

.after-results-map
    padding-top: calc(100vh - 80px)

    &.no-map
        padding-top: 0
    

#helperText
    font-size: x-small
    display: block

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield

.MuiSnackbar-root.snackbar div
    background-color: $primary-color
    color: white    
    font-weight: 600
    letter-spacing: 0.1em

    .MuiSvgIcon-root
        color: white

.html2canvas-container
    width: 3000px !important
    height: 3000px !important

/* CSS styling for before/after/avoid. */

.before
    break-before: page    

.css-for-pdf
    .before
        break-before: page
        margin-top: 2

    .before.pdf-top-space
        margin-top: 10.9in

    .after
        page-break-after: always

    .titleTable + span.icon
        display: none

    #pdf-title 
        padding-top: 0
        margin-top: -60px
        font-weight: bold
        font-size: 2em

        span.iconResult
            display: none

    h5, th
        font-weight: bold

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
        background-color: #fff
        box-shadow: none
        border-radius: 0
        border-bottom: 1px solid gray

        th
            font-weight: bold

    label#unit-label
        margin-top: -4px
        padding-left: 5px
        padding-right: 5px

#map.map-for-pdf
    //position: absolute
    margin-top: 10px
    margin-left: 10px

    .toggleLoadProject
        position: absolute
        //margin-left: 200px
        margin-top: 30px 
    
    #panel
        //left: 300px
        //margin-top: -100px

        .select-city
            background-color: #fff

.loader 
    width: 252px
    position: absolute
    z-index: 3
    height: 38px
    background-color: #000000
    opacity: 0.5
    margin-top: 6px
    left: 10px
    overflow: hidden

    &:after
        content: 'Loading'
        width: 100px
        height: 38px
        background: #c2c2c2
        position: absolute
        top: 0
        left: 0
        box-sizing: border-box
        animation: animloader 2s linear infinite
        line-height: 35px
        color: black
        text-align: center
        font-weight: 600