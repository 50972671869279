.horizontal-steeper{
  background-color: #F5F5F5;
  height: 90px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
/*Component one subtitle*/
.component_first_subtitle {
  background-color: #EFFBFF;
  padding: 21px;
  display: flex;
  width: 100%;
  margin-top: 37px !important;
  font-weight: 600 !important;
  
}
/*Title Button Header*/
.title_button_header{
  font-weight: Medium;
  font-size: 14px;
  color: #23BCED;
  text-transform: uppercase;
  line-height: 14px;
}
/*Paragraph Default*/
.paragraph_default{
  font-size: 16px;
  color:#000000DE;
  line-height: 24px;
}
/*Button back and next*/
/* .button_back  {
  background-color: #FFF !important;
} */

/*Link*/
.c-40-default-link {
  font-size: 14px !important;
  color: #23bced !important;
}
.c-40-label-default {
  font-weight: 600;
  color: rgb(53, 194, 53);
}
/*Default color Icon Delete*/
.c-40-default-color-iconDelete{
  color: #23bced !important;
}

/*Default Table with pure HTML*/
.titleTable {
font-size: 24px !important;
color: #000000DE;
opacity: 1;
text-transform: none !important;
display: inline-block;
margin-right: 10px;
}
span.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('../assets/info.svg');
}
span.iconResult {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('../assets/info.svg');
  margin-inline: 10px;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  font-family: "Roboto", sans-serif;

}
th, td {
  text-align: left;
  padding: 8px;

}
.c40 th {
  font-weight: bold;
  font-family: 'Montserrat';
  color: #000000DE;
}

.firstLineTable{
  font-family: 'Roboto';
  font-weight: normal;
  font-size: 16px;
  color: #000000DE;

}
.lastLineTable-html {
  font-family: 'Roboto';
  font-size: 16px;
  color: #23bced;
}
.gray-bg-others-tags-td {
  color: #858585;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 32px;
  text-transform: uppercase;
}
.gray-bg-others-tags-td::after {
  content: attr(data-percentage);
  font-size: 14px;
  color: #000000DE;
  margin-inline: 10px;
}

.lastLineTableNumbers-html {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 32px;
  color: #23bced;
  text-transform: uppercase;
}
.lastLineTableNumbers-html::after {
  content: attr(data-percentage);
  font-size: 14px;
  color: #000000DE;
  margin-inline: 10px;
}
/*C40 label default*/
.label-default  {
  font-family: 'Montserrat';
  font-size: 14px;
  color: #000000DE;
  font-weight: 500;
  text-transform: uppercase;
}
.label-default-comp112 {
  font-size: 16px;
  color: #000000DE;
  line-height: 14px;
}
.label-default-comp112-2 {
  font-size: 12px;
  color: #000000DE;
}
/* .physical-activity-th:first-of-type{
  text-transform: uppercase !important;
} */
.physical-activity-th{
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 16px;
  color: #000000DE;
}
/*Modal default*/
.default_modal {
  background-color: white !important;
  color: #23BCED !important;
  font-weight: 600 !important;
  border: 1px solid #23BCED !important;
}
.default_modal:hover{
  background-color: #23BCED !important;
  color: white !important;
}
/* STYLE FROM TABLE */

.table-td {
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  color: #23bced !important;
 
}
.table-td::after {
  content: attr(data-percentage);
  font-size: 16px;
  font-weight: normal;
  color: #000000DE;
  margin-inline: 10px;
}

span.iconClock {
  display: inline-block;
  width: 35px;
  height: 39px;
  margin-bottom: 5px;
  margin-right: 15px;
  vertical-align: middle;
  background-image: url('../assets/clock.svg');
}
span.iconOho {
  display: inline-block;
  width: 39px;
  height: 39px;
  margin-bottom: 5px;
  margin-right: 15px;
  vertical-align: middle;
  background-image: url('../assets/oho.svg');
}

/*FROM ALL OTHER TABLES*/
 .MuiTableCell-root{
  border-bottom: none !important;
}

/*TABLE FROM TAB SAVE*/
.css-1ex1afd-MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.borderedTable td, .borderedTable th {border: 1px #000 solid !important;
  word-break: break-word;}
.highlightedColumn {color:#03c245; font-weight: bold;text-align:right;padding-right: 1em;}
.borderlessColumn td, .borderlessColumn th {padding-bottom:1em; vertical-align: top;}
.borderedTable {
  max-width: 100%;
  table-layout: fixed;
}