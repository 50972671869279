*
  font-family: "Roboto","Helvetica","Arial",sans-serif  
  //font: normal normal normal 14px/14px "Roboto"

  /* Colors */
$primary-color: #23BCED

.c40

  .container 
      margin-bottom: 5rem

  button.MuiButtonBase-root.MuiButton-root
    -webkit-transition: 0.4s all ease-in-out
    -moz-transition: 0.4s all ease-in-out
    -o-transition: 0.4s all ease-in-out   
    transition: 0.4s all ease-in-out
    background-color: $primary-color
    color: #fff

  button.MuiButtonBase-root.MuiButton-root:hover
    color: #444444
    background-color: hsl(195deg 85% 75%)

  button.MuiButtonBase-root.MuiButton-root:active
    box-shadow: none

  button.MuiButtonBase-root.MuiButton-root.Mui-disabled
    background-color: transparent
    border: 1px solid #e1e1e1
    color: #000042

  button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined, button.simulationBtn
    background-color: white
    color: $primary-color
    
    &:hover
      background-color: $primary-color
      color: white
      .MuiSvgIcon-root    
        color: white

  button.simulationBtn
    svg
      margin-left: 10px
      filter: invert(66%) sepia(43%) saturate(2217%) hue-rotate(159deg) brightness(94%) contrast(97%)

    &:hover
      svg
        filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(11deg) brightness(114%) contrast(101%)
  
  .App-logo
    height: 40vmin
    pointer-events: none

  /*@media (prefers-reduced-motion: no-preference) 
    .App-logo 
      animation: App-logo-spin infinite 20s linear*/

  .App-header
    background-color: #282c34
    min-height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    font-size: calc(10px + 2vmin)
    color: white

  .App-link
    color: #61dafb

  @keyframes App-logo-spin 
    from
      transform: rotate(0deg)
    to
      transform: rotate(360deg)


  header + .MuiContainer-root
    max-width: 1170px
    padding-top: 40px

  // DatabaseCSS

.c40-style-page 
  padding: 40px

/* H1, H2...H6 Default Styles*/
.c40 h6
  line-height: 32px
  font-size: 20px
  font-weight: 600
  font-family: 'Montserrat'
  color: rgba(0, 0, 0, 0.87)

.c40 h5
  line-height: 39px
  font-size: 32px
  font-family: 'Montserrat'
  text-transform: uppercase
  color: rgba(0, 0, 0, 0.87)
  
  .horizontal-steeper
    background-color: #F5F5F5
    height: 90px
    align-items: center
    display: flex
    justify-content: center
    margin-top: 32px
  
  /*Component one subtitle*/
  .component_first_subtitle
    background-color: #EFFBFF
    padding: 21px
    display: flex
    width: 100%
    margin-top: 37px

  /*Title Button Header*/
  .title_button_header
    font-weight: Medium
    font-size: 14px
    color: #23BCED
    text-transform: uppercase
    line-height: 14px
  
  /*Paragraph Default*/
  .paragraph_default
    font-size: 16px
    color:#000000DE
    line-height: 24px
  
  /*Link*/
  .c-40-default-link
    font-size: 14px 
    color: #23bced 
  
  .c-40-label-default
    font-weight: 600
    color: rgb(53, 194, 53)
  
  /*Default color Icon Delete*/
  .c-40-default-color-iconDelete
    color: #23bced
  
  /*Default Table with pure HTML*/
  .titleTable
    font-size: 32px
    color: #000000DE
    opacity: 1
    text-transform: none
    display: inline-block
    margin-right: 10px
  
  span.icon
    display: inline-block
    width: 20px
    height: 20px
    background-image: url('../assets/info.svg')
  
  table 
    border-collapse: collapse
    width: 100%
    margin-top: 20px

  th, td
    text-align: left
    padding: 8px

  .c40 th
    font-weight: bold
    font-family: 'Montserrat'
    color: #000000DE
  
  .c40 th:first-child
    text-transform: uppercase
    font-size: 14px
    font-weight: normal
  
  .firstLineTable    
    font-weight: normal
    font-size: 16px
    color: #000000DE

  .lastLineTable-html 
    font-size: 16px
    color: #23bced
  
  .gray-bg-others-tags-td
    color: #858585
    font-family: 'Montserrat'
    font-weight: 500
    font-size: 32px
    text-transform: uppercase
  
  .gray-bg-others-tags-td::after 
    content: attr(data-percentage)
    font-size: 14px
    color: #000000DE
    margin-inline: 10px

  .lastLineTableNumbers-html
    font-family: 'Montserrat'
    font-weight: 500
    font-size: 32px
    color: #23bced
    text-transform: uppercase

  .lastLineTableNumbers-html::after
    content: attr(data-percentage)
    font-size: 14px
    color: #000000DE
    margin-inline: 10px
  
  /*Table Physical Activity*/
  .label-physical-activity
    font-family: 'Montserrat'
    font-size: 14px
    color: #000000DE
    font-weight: 500
    text-transform: uppercase
  
  .physical-activity-th
    font-family: 'Montserrat'
    font-weight: bold
    font-size: 16px
    color: #000000DE

#loginForm

  h2.MuiTypography-root
    font-weight: bold
    font-family: 'Montserrat'
    font-size: 20px
    line-height: 32px
    
  p
    margin-bottom: 24px

  .MuiDialogContent-root
    padding-bottom: 10px

  p+div
    margin-right: 24px

  .MuiDialogActions-root
    padding: 24px

  .MuiInputBase-root
    .MuiOutlinedInput-notchedOutline
      border-color: #23bced

  .MuiButton-contained
    background-color: #23bced

#stepper
  .MuiStepLabel-root.MuiStepLabel-horizontal
    cursor: pointer

  .Mui-completed, .Mui-active 
    color: #23bced

  .MuiStep-root.MuiStep-horizontal:first-child
    padding-left: 24px

  .MuiStep-root.MuiStep-horizontal:last-child
    padding-right: 24px

#btn-login
  position: absolute
  top: 40px
  right: 24px