.verticalStep {
    font-family: Roboto;
  }
  /* .verticalStepOne {
    margin-top: 16px;
  } */
  .verticalStep .upperLine {
    font-size: 14px;
    text-transform: uppercase;
    color: #23bced;
  }
  .verticalStep .upperLine .stepNumber {
    float: left;
    border-radius: 50%;
    font-size: 12px;
    width: 26px;
    line-height: 20px;
    padding: 3px;
    background: #23bced;
    color: #ffff;
    text-align: center;
    margin-right: 8px;
  }
  
  .verticalStep .upperLine .stepTitle {
    line-height: 26px;
  }
  .stepTitleOne {
    margin-top: 37px;
  }
  
  .verticalStep .textualContent {
    clear: both;
    margin-left: 13px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: solid 1px #0000001f;
    padding: 8px 0px 16px 16px;
    font-size: 16px;
    color: #000000de;
  }
  .textualContentSeven  {
    border-left: 0;
    clear: both;
    margin-left: 13px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: 0;
    padding: 8px 0px 16px 16px;
    font-size: 16px;
    color: #000000de;
  }

  .textualContentEight  {
    border-left: 0;
    clear: both;
    margin-left: 13px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: 0;
    padding: 8px 0px 16px 16px;
    font-size: 16px;
    color: #000000de;
  }

  .textualContentNine  {
    border-left: 0;
    clear: both;
    margin-left: 13px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-left: 0;
    padding: 8px 0px 16px 16px;
    font-size: 16px;
    color: #000000de;
  }

  .MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    color: #23bced;
  }

  .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
   background-color: #23bced !important;
}

.c40 .c-40__Title-about{
  margin-top: 25px;
  margin-bottom: 10px;
}

.c40 .c-40__Title-aboutOne{
  margin-top: 34px;
}

.c40 .c-40__Paragraph-about{
  margin-top: 16px;
  margin-bottom: 16px;
}
.c40 .c-40__Li-about{
  margin-top: 16px;
}
/* .MuiTypography-root{
  margin-top: 16px !important;
} */
.c40 .c-40__Link-about{
  font-size: 14px;
  color: #23bced;
}
