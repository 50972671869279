/*import css-variables from App.min.css*/
/* Colors */
$primary-color: #23BCED

@keyframes slideaway
    from
        display: block
    to
        transform: translateY(40px)
        opacity: 0

.map
    font-family: sans-serif
    text-align: center

.map-container, .map-container-location
    height: calc(100vh - 120px)
    width: 100%
    padding-right: 0
    transition: all 1s ease-in-out

.map-container, .map-container-location

    .ol-zoom
        bottom: 0.5em
        right: 10px
        z-index: 1
        top: unset
        left: unset

    .ol-control button
        height: 1.8em
        width: 1.8em
        background-color: $primary-color
        color: white
        transition: all .4s ease
        box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16)

        &:hover
            outline: none
            color: gray
            background-color: #11a4d4
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.16)

.map-container.got-city
    /*width: calc(100% - 416px)*/
    padding-right: 426px

.overlay
    position: absolute
    height: calc(100% - 120px)
    width: 100%
    z-index: 20
    background-color: rgba(0,0,0,0.5)
    color: #FFFFFF
    opacity: 1

.overlay.hide
    transition: opacity 200ms, display 200ms
    opacity: 0
    display: none

.overlay .tool-intro
    display: flex
    align-items: center
    justify-content: center
    height: calc(100% - 240px)
    padding-top: 120px

.overlay .tool-text
    //position: absolute
    //top: 50%
    //left: 50%
    //transform: translate(-50%, -50%)
    max-width: 700px
    padding: 0 35px

    h1
        //letter-spacing: .15rem
        letter-spacing: .04em
        text-align: center
        border-bottom: 1px solid #FFFFFF
        //padding-bottom: 10px
        //line-height: 24px

    p
        padding: 5px 0px
        font-size: 16px
        line-height: 24px
        margin-bottom: 0

    a
        font-weight: 600
        color: $primary-color

.overlay button
    margin-top: 20px
    margin-left: 50%
    -webkit-transform: translateX(-50%)
    -moz-transform: translateX(-50%)
    -ms-transform: translateX(-50%)
    -o-transform: translateX(-50%)
    transform: translateX(-50%)

@media (max-width: 960px) or (max-height: 760px)

    .overlay button
        position: absolute
        bottom: 30px

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
    max-height: 44vh

li.MuiButtonBase-root.MuiMenuItem-root
    min-height: 26px

    &:hover
        font-weight: 600

#selected-city
    position: absolute
    //top: calc(100vh)
    top: calc(100vh - 73px)
    z-index: 10
    left: -180px
    background-color: #fff
    padding: 10px
    transition: all .4s ease
    min-height: 52px
    min-width: 82px
    box-shadow: 6px 6px 6px rgba(0,0,0,.16)

#selected-city.got-city
    top: calc(100vh - 73px)
    left: 10px

#selected-city.got-city.got-score
    top: calc(100vh - 210px)
    min-width: 130px

    .color-scale
        height: 100px
        margin-top: 10px
        width: 40px

        &:before, &:after
            position: absolute
            margin-left: 50px
            font-size: small
            font-weight: 600

        &:before
            content: "Max score"

        &:after
            content: "Min score"
            bottom: 10px

        span
            width: 50px
            height: 10px
            display: block

            span
                margin-left: 60px
                position: absolute
                width: auto
                font-size: small
                font-weight: 600

                &last-score
                    margin-top: -5px

#selected-city.got-city h6
    margin-bottom: 0
    line-height: 1.1em

#selected-city .indicator
    height: 0
    transition: all .4s ease

#selected-city.got-score .indicator
    height: auto

#selected-city.got-city .indicator div
    opacity: 0

#selected-city.got-score .indicator div
    opacity: 1

/* Panel */
#panel
    position: absolute
    z-index: 10
    top: 120px
    right: 10px
    transition: right .4s ease

#panel.got-city
    right: 436px

#panel .MuiSelect-select
    padding: 8px 34px 8px 10px

#panel .MuiSelect-icon
    color: rgb(35 188 237)

.c40 .select-city
    margin-top: 6px
    width: 250px
    background-color: #FFFFFF
    border-radius: 5px
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16)
    transition: all .4s ease

    &:hover
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.16)

.side-panel
    width: 426px
    background-color: #FFFFFF
    height: calc(100vh - 120px)
    position: absolute
    left: 260px
    top: 0px
    padding: 20px 15px
    overflow-y: auto

    .top-color-bar
        width: 100%
        height: 35px
        background-color: #008CB9
        position: absolute
        left: 0
        top: 0px

    select#indicator-select
        padding: 10px

    .select-indicator
        width: 100%

    .MuiSvgIcon-root
        color: rgb(35 188 237)

    .MuiPaper-root
        box-shadow: none

    .MuiPaper-root.Mui-expanded
        margin: 0

    .MuiButtonBase-root
        padding: 0

    .MuiAccordionSummary-expandIconWrapper
        margin-top: 15px

.MuiSvgIcon-root
    color: $primary-color

.side-panel .MuiAccordionSummary-content
    margin: 0

.side-panel .MuiAccordionSummary-content.Mui-expanded
    margin: 0

.side-panel .MuiAccordionDetails-root
    padding: 0

.side-panel h6.MuiTypography-root span
    font: normal normal bold 37px/22px Montserrat

.side-panel h6.MuiTypography-root span:first-child
    color: rgba(35, 188, 237, 1)

.side-panel .MuiAccordion-root:before, .side-panel .MuiButtonBase-root:before
    background-color: transparent

.side-panel .MuiPaper-root.no-indicator
    display: none

.side-panel .MuiAccordionDetails-root ul
    list-style-type: decimal

.side-panel .MuiAccordionDetails-root ul li
    font-size: 18px
    line-height: 24px

.side-panel .MuiAccordionDetails-root ul li.city-rank
    color: rgba(35, 188, 237, 1)
    font-weight: bold

.side-panel

    .rank-block
        transition: all .4s ease-in-out

    .info-block
        background-color: #EFFBFF
        padding: 14px
        margin-top: 40px
        font-weight: 600
        text-transform: uppercase
        font-size: 0.9em

        span:first-child
            color: $primary-color
            display: block
            font-weight: bolder

div#ranks-content

    .MuiCollapse-root
        overflow-y: hidden

    .MuiAccordionDetails-root
        max-height: 240px
        overflow-y: auto

    ul
        padding-left: 2.7rem

.side-panel .rank-chart.no-indicator
    display: none

.side-panel .rank-chart
    display: block
    margin-top: 25px
    height: 280px
    margin-bottom: 10px
    width: 100%

    .chart-container
        margin-left: -5px

.download-icon
    cursor: pointer

/* Popup */
.ol-popup
    position: absolute
    background-color: rgb(0 0 0 / 60%)
    box-shadow: 4px 4px 7px 0px rgba(0,0,0,0.3)
    padding: 4px 10px
    border-radius: 10px
    /*border: 1px solid #cccccc*/
    bottom: 10px
    left: -16px
    color: #fff
    font-weight: 600
    white-space: nowrap

.ol-popup:after, .ol-popup:before
    top: 100%
    border: solid transparent
    content: " "
    height: 0
    width: 0
    position: absolute
    pointer-events: none

.ol-popup:after
    border-top-color: rgb(0 0 0 / 60%)
    border-width: 10px
    left: 8px
    margin-left: -1px

/*.ol-popup:before 
 border-top-color: #cccccc
 border-width: 11px
 left: 48px
 margin-left: -11px*/
